exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-facts-cdc-facts-js": () => import("./../../../src/pages/facts/cdc-facts.js" /* webpackChunkName: "component---src-pages-facts-cdc-facts-js" */),
  "component---src-pages-facts-health-effects-js": () => import("./../../../src/pages/facts/health-effects.js" /* webpackChunkName: "component---src-pages-facts-health-effects-js" */),
  "component---src-pages-facts-myth-busting-js": () => import("./../../../src/pages/facts/myth-busting.js" /* webpackChunkName: "component---src-pages-facts-myth-busting-js" */),
  "component---src-pages-facts-setting-the-record-straight-js": () => import("./../../../src/pages/facts/setting-the-record-straight.js" /* webpackChunkName: "component---src-pages-facts-setting-the-record-straight-js" */),
  "component---src-pages-facts-signs-of-addiction-js": () => import("./../../../src/pages/facts/signs-of-addiction.js" /* webpackChunkName: "component---src-pages-facts-signs-of-addiction-js" */),
  "component---src-pages-facts-signs-of-lung-disease-js": () => import("./../../../src/pages/facts/signs-of-lung-disease.js" /* webpackChunkName: "component---src-pages-facts-signs-of-lung-disease-js" */),
  "component---src-pages-facts-truth-initiative-facts-js": () => import("./../../../src/pages/facts/truth-initiative-facts.js" /* webpackChunkName: "component---src-pages-facts-truth-initiative-facts-js" */),
  "component---src-pages-facts-vaping-side-effects-js": () => import("./../../../src/pages/facts/vaping-side-effects.js" /* webpackChunkName: "component---src-pages-facts-vaping-side-effects-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-eight-vaping-statistics-that-may-shock-you-js": () => import("./../../../src/pages/news/eight-vaping-statistics-that-may-shock-you.js" /* webpackChunkName: "component---src-pages-news-eight-vaping-statistics-that-may-shock-you-js" */),
  "component---src-pages-news-fda-warns-vape-company-for-bizarre-medical-claims-js": () => import("./../../../src/pages/news/fda-warns-vape-company-for-bizarre-medical-claims.js" /* webpackChunkName: "component---src-pages-news-fda-warns-vape-company-for-bizarre-medical-claims-js" */),
  "component---src-pages-news-federal-lawsuit-filed-against-e-cigarette-distributors-js": () => import("./../../../src/pages/news/federal-lawsuit-filed-against-e-cigarette-distributors.js" /* webpackChunkName: "component---src-pages-news-federal-lawsuit-filed-against-e-cigarette-distributors-js" */),
  "component---src-pages-news-juul-to-pay-hefty-price-in-teen-vaping-probe-js": () => import("./../../../src/pages/news/juul-to-pay-hefty-price-in-teen-vaping-probe.js" /* webpackChunkName: "component---src-pages-news-juul-to-pay-hefty-price-in-teen-vaping-probe-js" */),
  "component---src-pages-news-lung-injury-js": () => import("./../../../src/pages/news/lung-injury.js" /* webpackChunkName: "component---src-pages-news-lung-injury-js" */),
  "component---src-pages-news-the-hidden-impact-of-vape-culture-js": () => import("./../../../src/pages/news/the-hidden-impact-of-vape-culture.js" /* webpackChunkName: "component---src-pages-news-the-hidden-impact-of-vape-culture-js" */),
  "component---src-pages-news-vape-death-from-covid-js": () => import("./../../../src/pages/news/vape-death-from-covid.js" /* webpackChunkName: "component---src-pages-news-vape-death-from-covid-js" */),
  "component---src-pages-news-vape-explodes-teens-jaw-js": () => import("./../../../src/pages/news/vape-explodes-teens-jaw.js" /* webpackChunkName: "component---src-pages-news-vape-explodes-teens-jaw-js" */),
  "component---src-pages-news-vape-your-way-to-covid-19-js": () => import("./../../../src/pages/news/vape-your-way-to-covid-19.js" /* webpackChunkName: "component---src-pages-news-vape-your-way-to-covid-19-js" */),
  "component---src-pages-news-vaping-and-covid-sypmtoms-js": () => import("./../../../src/pages/news/vaping-and-covid-sypmtoms.js" /* webpackChunkName: "component---src-pages-news-vaping-and-covid-sypmtoms-js" */),
  "component---src-pages-news-vaping-puts-teen-on-life-support-js": () => import("./../../../src/pages/news/vaping-puts-teen-on-life-support.js" /* webpackChunkName: "component---src-pages-news-vaping-puts-teen-on-life-support-js" */),
  "component---src-pages-news-weird-vape-accidents-js": () => import("./../../../src/pages/news/weird-vape-accidents.js" /* webpackChunkName: "component---src-pages-news-weird-vape-accidents-js" */),
  "component---src-pages-quitting-good-habits-js": () => import("./../../../src/pages/quitting/good-habits.js" /* webpackChunkName: "component---src-pages-quitting-good-habits-js" */),
  "component---src-pages-quitting-quitting-tips-js": () => import("./../../../src/pages/quitting/quitting-tips.js" /* webpackChunkName: "component---src-pages-quitting-quitting-tips-js" */),
  "component---src-pages-quitting-text-to-quit-js": () => import("./../../../src/pages/quitting/text-to-quit.js" /* webpackChunkName: "component---src-pages-quitting-text-to-quit-js" */)
}

